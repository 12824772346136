import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/index.css';
import '../css/admin.css';
import Header from '../components/Header';
import toast from 'react-hot-toast';

import { Navigate } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';

import { timeOutline, cellularOutline, colorWandOutline, chevronForward, chevronDown } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Admin() {
  const [items, setItems] = useState([]);
  const [collection, setCollection] = useState("article");
  const [itemId, setItemId] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [loadMore, setLoadMore] = useState(true)
  const [start, setStart] = useState(0)

  // const [editedItem, setEditedItem] = useState(null);
  const [editedItem, setEditedItem] = useState({ description: '' });


  function timeAgo(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();

    const seconds = Math.floor((now - date) / 1000);

    if (seconds < 60) {
      return 'Just now';
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes}m`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours}h`;
    }

    const days = Math.floor(hours / 24);
    if (days < 7) {
      return `${days}d`;
    }

    const weeks = Math.floor(days / 7);
    return `${weeks}w`;
  }

  function fetchItems(collection) {
    setStart(0)
    setLoadMore(true)
    axios.get(`${process.env.REACT_APP_API_URL}/api/${collection}s?s=0`)
      .then(response => {
        setItems(response.data.data);
        setEditedItem({ ...response.data.data[0] })
        if (response.data.data.length < 10) {
          setLoadMore(false)
        } else {
          setStart(start + 10)
        }
      })
      .catch(error => {
        toast.error(`Error fetching ${collection}s`)
      });
  }

  function fetchMoreItems() {
    setStart(start + 10)
    axios.get(`${process.env.REACT_APP_API_URL}/api/${collection}s?s=${start}`)
      .then(response => {
        setItems(prevItems => [...prevItems, ...response.data.data]);
        setEditedItem({ ...response.data.data[0] })
        if (response.data.data.length < 10) {
          setLoadMore(false)
        } else {
          setStart(start + 10)
        }
      })
      .catch(error => {
        toast.error(`Error fetching ${collection}s`)
      });
  }

  const fetchItemById = async (collection, id) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/api/${collection}?id=${id}`, { withCredentials: true })
      .then(response => {
        setSelectedItem(response.data.data);
        setEditedItem({ ...response.data.data });

      })
      .catch(error => {
        toast.error(`Error fetching ${itemId} from ${collection}`)
      });
  }

  useEffect(() => {
    fetchItems(collection);
  }, [collection,]);

  const handleInputChange = (event) => {
    setItemId(event.target.value);
  };

  const handleFetchItem = () => {
    if (itemId) {
      fetchItemById(collection, itemId);
    }
  };

  const handleTitleChange = (event) => {
    setEditedItem({
      ...editedItem,
      title: event.target.value
    });
  };

  const handleDescriptionChange = (content) => {
    setEditedItem({
      ...editedItem,
      description: content
    });
  };

  const handleEditItem = () => {
    axios.put(`${process.env.REACT_APP_API_URL}/api/${collection}`, editedItem, { withCredentials: true })
      .then(response => {
        if (response.data.message.id) {
          setEditedTitle('');
          setEditedDescription('');
          toast.success("Posted")
        } else {
          toast.error(response.data.message)
          console.log('Failed to add new item.');
        }
      })
      .catch(error => {
        toast.error(error.data.message)
        console.error('Error adding new item:', error);
      });
  };

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
    setEditedItem(item)
  }


  const handleSetCollection = (collection) => {
    setCollection(collection);
    setSelectedItem(null);
  }

  const handlePostItem = () => {
    const newItem = {
      title: editedTitle,
      description: editedDescription
    };

    // Make a POST request to add the new item
    axios.post(`${process.env.REACT_APP_API_URL}/api/${collection}`, newItem, { withCredentials: true })
      .then(response => {
        if (response.data.message.id) {
          setEditedTitle('');
          setEditedDescription('');
          toast.success("Posted")
        } else {
          toast.error(response.data.message)
          console.log('Failed to add new item.');
        }
      })
      .catch(error => {
        toast.error(error.data.message)
        console.error('Error adding new item:', error);
      });
  };

  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');

  const handleNewTitleChange = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleNewDescriptionChange = (content) => {
    setEditedDescription(content);
  };

  const handleDeleteItem = async () => {
    if (!selectedItem) return;

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/${collection}`, { data: { id: selectedItem.id }, withCredentials: true });
      if (response.data.message.id) {
        toast.success("Deleted")
      } else {
        toast.error(response.data.message)
        console.log('Failed to add new item.');
      }
    } catch (error) {
      toast.error(error.data.message)
      console.log('Failed to add new item.');
    }
  };

  const [activity, setActivity] = useState([]);

  const fetchActivity = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/activity?s=0`)
      .then(response => {
        setActivity(response.data.data);
      })
      .catch(error => {
        toast.error(`Error fetching activity`);
      });
  };

  useEffect(() => {
    fetchActivity();
  }, []);


  const [channelVisibility, setChannelVisibility] = useState({
    search: true,
    edit: true,
    post: true,
    activity: true,
  });

  function toggleChannel(channel) {
    setChannelVisibility(prevState => ({
      ...prevState,
      [channel]: !prevState[channel]
    }));
  }

  const truncateDescription = (description) => {
    // Check if the description is longer than 128 characters
    if (description.length > 128) {
      // If yes, truncate it and add ellipsis
      return description.substring(0, 128) + '...';
    } else {
      // If not, return the original description
      return description;
    }
  };

  const { username, password } = useAuth();

  if (!username || !password) {
    return <Navigate to="/login" />;
  }


  return (
    <div className='admin-wrapper'>
      <Header />
      <section className='admin'>
        <section className='admin-section'>
          <div className='bx' onClick={() => toggleChannel('search')}>
            <div>
              <h1>Search {channelVisibility.search ? (<><IonIcon icon={chevronDown} /></>) : (<><IonIcon icon={chevronForward} /></>)}</h1>
            </div>
          </div>
          {channelVisibility.search && (
            <>
              <div className='bx search'>
                <input placeholder='ID' value={itemId} onChange={handleInputChange} type="number" />
                <button onClick={handleFetchItem}>Fetch Item</button>
              </div>
              <div className='bx search'>
                <button className={collection === "article" ? "active" : ""} onClick={() => handleSetCollection("article")}> Articles </button>
                <button className={collection === "faq" ? "active" : ""} onClick={() => handleSetCollection("faq")}> FAQs </button>
              </div>

              <div className='bx list'>
                {items.map(item => (
                  <div className='list-item' key={item.id} onClick={() => handleSelectedItem(item)}>
                    <div className='item-head'>
                      <span>{item.id}</span>
                      <div className='head-icons'>
                        {item.views && (
                          <span>{item.views} <IonIcon icon={cellularOutline} /></span>
                        )}

                        <span>{timeAgo(item.timestamp)} <IonIcon icon={timeOutline} /></span>
                      </div>
                    </div>
                    <div className='item-foot' >
                      <span>{item.title}</span>
                      <div dangerouslySetInnerHTML={{ __html: truncateDescription(item.description) }} />
                    </div>
                  </div>
                ))}
                {loadMore && (
                  <button onClick={fetchMoreItems}> Load More </button>
                )}
              </div>
            </>
          )}
        </section>
        <section className='admin-section'>
          <div className='bx' onClick={() => toggleChannel('edit')}>
            <div>
              <h1>Edit {collection.charAt(0).toUpperCase() + collection.slice(1)}{channelVisibility.edit ? (<><IonIcon icon={chevronDown} /></>) : (<><IonIcon icon={chevronForward} /></>)}</h1>
            </div>
          </div>
          {selectedItem && channelVisibility.edit && (
            <div className='bx edit'>
              <div className='list-item edit-item'>
                <div className='item-head'>
                  <span>{selectedItem.id}</span>
                  <span>{timeAgo(selectedItem.timestamp)}</span>
                </div>
                <div className='item-foot edit-foot'>
                  <input value={editedItem.title} onChange={handleTitleChange} />
                  <ReactQuill theme="snow" value={editedItem.description} onChange={handleDescriptionChange} />
                </div>
                <div className='item-head'>
                  <button onClick={handleEditItem}>Edit</button>
                  <button onClick={handleDeleteItem} className='delete'>Delete</button>
                </div>
              </div>
            </div>
          )}
          <div className='bx' onClick={() => toggleChannel('post')}>
            <div>
              <h1>Post a New {collection.charAt(0).toUpperCase() + collection.slice(1)}{channelVisibility.post ? (<><IonIcon icon={chevronDown} /></>) : (<><IonIcon icon={chevronForward} /></>)}</h1>
            </div>
          </div>
          {channelVisibility.post && (

            <div className='bx edit'>
              <div className='list-item edit-item'>
                <div className='item-head'>
                  <span>New</span>
                  <span>Now</span>
                </div>
                <div className='item-foot edit-foot'>
                  <input
                    placeholder='Title or Question'
                    value={editedTitle}
                    onChange={handleNewTitleChange}
                  />
                  <ReactQuill
                    theme='snow'
                    value={editedDescription}
                    onChange={handleNewDescriptionChange}
                  />
                </div>
                <div className='item-head'>
                  <button onClick={handlePostItem}>Post</button>
                </div>
              </div>
            </div>
          )}
        </section>
        <section className='admin-section'>
          <div className='bx' onClick={() => toggleChannel('activity')}>
            <div>
              <h1>Activity {channelVisibility.activity ? (<><IonIcon icon={chevronDown} /></>) : (<><IonIcon icon={chevronForward} /></>)}</h1>
            </div>
          </div>
          {channelVisibility.activity && (
            <div className='bx list'>
              {activity.map(item => (
                <div className='list-item' key={item.itemId}>
                  <div className='item-head'>
                    <span>{item.itemId}</span>
                    <div className='head-icons'>
                      <span>{timeAgo(item.timestamp)} <IonIcon icon={timeOutline} /></span>
                    </div>
                  </div>
                  <div className='item-head'>
                    <span>{item.person}</span>
                    <div className='head-icons'>
                      <span>{item.action} <IonIcon icon={colorWandOutline} /></span>
                    </div>

                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
      </section>
    </div>
  );
}

export default Admin;
