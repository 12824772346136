import '../css/index.css';
import '../css/login.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../images/logo.svg'
import Theme from '../components/Theme';


function Login() {
  const { toggleTheme, setTheme, themeIcon } = Theme();

  const { setAuthCredentials, username, password } = useAuth();
  const [usernameInput, setUsernameInput] = useState(username || '');
  const [passwordInput, setPasswordInput] = useState(password || '');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
  }, [usernameInput, passwordInput]);

  useEffect(() => {
    if (username && password) {
      setIsLoggedIn(true);
    }
  }, [username, password]);

  const handleSignIn = () => {
    if (!usernameInput || !passwordInput) {
      toast.error("Please fill both fields", 5000);
      return;
    }
    axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { username: usernameInput, password: passwordInput }, { withCredentials: true })
      .then(response => {
        setAuthCredentials(usernameInput, passwordInput);
        setIsLoggedIn(true);
        toast.success("Success!", 3000);
      })
      .catch(error => {
        toast.error(error.message, 5000);
      });
  };

  useEffect(() => {
    let theme = localStorage.getItem("theme");
    setTheme(theme);
  }, []);




  if (isLoggedIn) {
    return <Navigate to="/admin" />;
  }
  return (
    <div className='app-login'>
      <section className='login-section login-left'>
        <img src={logo} alt="Zero logo" />
      </section>
      <section className='login-section login-wrapper'>
        <div className='login'>
          <h1> Sign In </h1>
          <p>Enter your staff email to sign into your account</p>
          <div className='login-row'>
            <label>Username</label>
            <input value={usernameInput} onChange={(e) => setUsernameInput(e.target.value)} />
          </div>
          <div className='login-row'>
            <label>Password</label>
            <input placeholder='' value={passwordInput} onChange={(e) => setPasswordInput(e.target.value)} />
          </div>
          <div className='login-row'>
            <button onClick={handleSignIn}> Sign In with Email </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
