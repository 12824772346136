import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState(sessionStorage.getItem('username') || '');
  const [password, setPassword] = useState(sessionStorage.getItem('password') || '');

  const setAuthCredentials = (username, password) => {
    sessionStorage.setItem('username', username);
    sessionStorage.setItem('password', password);
    setUsername(username);
    setPassword(password);
  };

  const clearAuthCredentials = async () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('password');
    setUsername('');
    setPassword('');

    try {
      const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/logout`, { withCredentials: true });
  
      return response.data.result;
    } catch (error) {
      console.error('Error checking user:', error);
      
    }
  };

  return (
    <AuthContext.Provider value={{ username, password, setAuthCredentials, clearAuthCredentials }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export const checkUser = async () => {
  
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user`, { withCredentials: true });

    if (response.data.result === true) {
      return (response.data.username);
    }
  } catch (error) {
    console.error('Error checking user:', error);
  }
};
