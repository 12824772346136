import React, { useEffect, useState } from 'react';

import '../css/index.css';
import '../css/Header.css'
import Theme from './Theme';
import logo from '../images/logo.svg'
import { useAuth } from '../context/AuthContext';
import { logOutOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import toast from 'react-hot-toast';

function Header() {

    const { toggleTheme, setTheme, themeIcon } = Theme();
    const { username, clearAuthCredentials } = useAuth();

    const handleLogout = async () => {
        try {
            const success = await clearAuthCredentials();
            if (success) {
                toast.success("Logout successful");
            } else {
                toast.error("Logout failed");
            }
        } catch (error) {
            toast.error('Error logging out:', error);
        }
    }



    useEffect(() => {
        let theme = localStorage.getItem("theme");
        setTheme(theme);
    }, []);
    return (
        <div className='header-wrapper'>
            <div className='logo'>
                <img src={logo} />
            </div>
            <div className='nav'>
                <a className='nav-btn' onClick={handleLogout} >
                    Logout
                </a>
                <a className='nav-btn' onClick={toggleTheme} >
                    <img src={themeIcon} />
                </a>


            </div>

        </div>
    );
}

export default Header;
